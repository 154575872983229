import { isChatbot, JS_DOWNLOADING_LOADER_ID } from './constants';
import { dispatchLoadingMessage } from './dispatch';
import { listen, renderSpinner } from './view';

/**
 * @category Init Loading Widget
 * @example
  // In webpack.config.js (already done in the shared webpack config)
  entry: {
    spinner: ['@axa-asia/emma-loading-widget/dist/init'],
    main: ...
  }
 */
export const init = () => {
  window.addEventListener('DOMContentLoaded', () => {
    renderSpinner(document.body);
    listen();

    dispatchLoadingMessage({
      loaderId: JS_DOWNLOADING_LOADER_ID,
      target: isChatbot() ? 'overlay' : 'rhs',
      isLoading: true,
      content: 'spinner',
    });
  });
};

init();
