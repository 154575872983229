import { LoaderState } from './types';

const state: LoaderState = {
  content: 'spinner',
  currentTarget: typeof document === 'undefined' ? null : document.body,
  show: false,
  minAnimEndTime: 0,
};

export const getState = () => state;
