import {
  BUFFER_TIME_INTERVAL,
  MAX_ANIM_TIME_INTERVAL,
  MIN_ANIM_TIME_INTERVAL,
  POST_MESSAGE_TYPE,
  REGEX_EMMA_ORIGIN,
  RHS_CONTAINER_ID,
  isDev,
  getTargetOrigin,
} from './constants';
import { dispatchLoadingUpdateEvent } from './dispatch';
import { PostData } from './types';
import { debounce } from './utils';
import { getState } from './state';

const queue: Array<PostData> = [];

const getMinAnimTime = () => {
  const state = getState();

  switch (state.content) {
    case 'spinner':
      return MIN_ANIM_TIME_INTERVAL;
    default:
      return MIN_ANIM_TIME_INTERVAL;
  }
};

const deboucnedOnLoadingUpdate = debounce(() => {
  const state = getState();

  if (state.show) {
    if (!state.minAnimEndTime) {
      const minTime = getMinAnimTime();
      state.minAnimEndTime = Date.now() + minTime;
      // validate state after minimum animation time
      setTimeout(setState, minTime);
    }
  } else {
    state.minAnimEndTime = 0;
  }
  dispatchLoadingUpdateEvent();
}, BUFFER_TIME_INTERVAL);

/**
 * Current loading screen === 1st item in queue
 */
const setState = () => {
  const state = getState();

  if (!queue[0]) {
    if (state.minAnimEndTime > Date.now()) return;

    state.show = false;
    deboucnedOnLoadingUpdate();
    return;
  }

  state.show = true;
  state.content = queue[0].content;

  const t = queue[0].target;
  if (t instanceof HTMLElement) state.currentTarget = t;
  else if (t === 'rhs') {
    const c = document.getElementById(RHS_CONTAINER_ID);
    state.currentTarget = c || document.body;
  } else state.currentTarget = document.body;

  deboucnedOnLoadingUpdate();
};

// The queue should remove the first item when the condition is met
// 1. if the first item reach maxAnimEndTime
// 2. if the queue contains a non-loading item with the same loaderId as the first item
const shouldAnimEvtsPop = () => {
  // if queue is empty
  if (!queue[0]) return false;

  const firstItem = queue[0];

  // if first item reach maxAnimEndTime
  if (firstItem.maxAnimEndTime < Date.now()) return true;
  
  // if the queue contains a non-loading item with the same loaderId as the first item
  return queue.some(({ isLoading, loaderId }) => !isLoading && loaderId === firstItem.loaderId);
};

const onLoadingEnd = () => {
  while (shouldAnimEvtsPop()) {
    queue.shift();
  }
};

const onData = (message: PostData) => {
  queue.push(message);
  onLoadingEnd();
  setState();

  // check animation timeout
  if (message.isLoading) {
    // Add fail-safe to prevent user forgot to hide the loading screen
    // This is the global timeout for the loading screen
    setTimeout(() => {
      onLoadingEnd();
      setState();
    }, MAX_ANIM_TIME_INTERVAL);
  }
};

export const createObserver = () => {
  const fn = (event: MessageEvent<PostData>) => {
    if (
      !isDev() &&
      event.origin !== getTargetOrigin() &&
      !REGEX_EMMA_ORIGIN.test(event.origin) // Allow messages from Emma
    )
      return;
    const data = event.data;
    if (data.type !== POST_MESSAGE_TYPE) return;
    onData(data);
  };

  return {
    listen: () => window.addEventListener('message', fn, false),
    stop: () => window.removeEventListener('message', fn),
  };
};
